import React, { useState, useEffect } from 'react';
import { useNavigate, useParams, useSearchParams, useLocation, Link } from 'react-router-dom';
import { Container, Alert, Spinner, Button, Row, Col } from 'react-bootstrap';
import { ApiManager } from '../../../core/api-manager';
import { Helmet } from 'react-helmet';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


export default function BlogSearch({route}) {

    const navigate = useNavigate();
    const [errors, setErrors] = useState('');
    const { p } = useParams();
    const page = parseInt(p)

    const [data, setData] = useState(null);
    const [totalPages, setTotalPages] = useState(0);

    const [searchParams, setSearchParams] = useSearchParams();
    const {state} = useLocation();
    const [search, setSearch] = useState(state ? state.query : '')


    useEffect(() => {
        init()
        console.log(state)
    }, [page]);

    
    async function init() {
        let body = {
            page: page - 1,
            query: search
        }
        var response = await ApiManager.sendRequest('/public/get_blog', body) 
        console.warn(response)
        if(response.success === 1) {
            setData(response.body)
            setTotalPages(response.pages)
        }
        else {
            setErrors('Errore. Riprova più tardi.')
        }
    }

    function changePage(p) {
        navigate('/search/' + p, {
            state: {
                query: search
            }
        })
    }

    function goToPrevPage() {
        if(page > 1) {
            changePage(page-1)
        }
    }
    function goToNextPage() {
        if(page < totalPages) {
            changePage(page+1)
        }
    }
    function goToFirstPage() {
        if(page !== 1) {
            changePage(1)
        }
    }
    function goToLastPage() {
        if(page !== totalPages) {
            changePage(totalPages)
        }
    }



    if (data !== null) {
        return (
            <>
                <Helmet>
                    <title>{'Search - ' + search + ' - ' + page}</title>
                </Helmet>

                <Container fluid className='mt-5 mb-5 pt-5 pb-5 text-white'>
                    <h1 className='text-center'>Results for: '{search}'.</h1>

                    {data.map((item, i) => {
                        return(
                            <Row key={i} className='mt-5 mb-5'>
                                <Col md={2}></Col>
                                <Col xs={12} md={6} className='text-start'>
                                    <h4>
                                        <Link to={'/entry/' + item.id}>{item.title}</Link>
                                    </h4>
                                </Col>
                                <Col xs={12} md={2} className='text-end'>
                                    <p className='small text-secondary'>{item.date && moment(item.date).format('DD/MM/YYYY HH:mm')}</p>
                                </Col>
                                <Col md={2}></Col>
                            </Row>
                        )
                    })}
                    
                    <Row>
                        <Col xs={12} md={12} className='text-center'>
                            <Button className={page === 1 ? 'disabled mx-1' : 'mx-1'} onClick={goToFirstPage}>
                                <FontAwesomeIcon icon="arrow-left" color={'#aaa'} />
                            </Button>
                            <Button className={page <= 1 ? 'disabled mx-1' : 'mx-1'} onClick={goToPrevPage}>
                                <FontAwesomeIcon icon="chevron-left" color={'#aaa'} />
                            </Button>
                            <Button disabled className='mx-1' style={{ width: 100 }}>{page}</Button>
                            <Button className={page >= totalPages ? 'disabled mx-1' : 'mx-1'} onClick={goToNextPage}>
                                <FontAwesomeIcon icon="chevron-right" color={'#aaa'} />
                            </Button>
                            <Button className={page >= totalPages ? 'disabled mx-1' : 'mx-1'} onClick={goToLastPage}>
                                <FontAwesomeIcon icon="arrow-right" color={'#aaa'} />
                            </Button>
                        </Col>
                        <Col xs={12} md={12}>
                            
                        </Col>
                    </Row>
                </Container>
            </>
        )
    }
    else {
        return (
            <Container fluid='md' className='mt-5 mb-5'>
                {errors.length > 0 &&
                    <Alert variant='danger'>
                        {errors}
                    </Alert>
                }
                <div className='text-center p-5'>
                    <Spinner animation="border" variant="secondary" />
                    <p className='text-secondary'>Loading</p>
                </div>
            </Container>
        )
    }
};